import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nbia-universal-menu',
  templateUrl: './universal-menu.component.html',
  styleUrls: ['./universal-menu.component.scss']
})
export class UniversalMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
