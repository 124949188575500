import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'nbia-list-search',
    templateUrl: './list-search.component.html',
    styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit {
    sharedListQueryInput;

    constructor() {
    }

    ngOnInit() {
    }

    onSearchClick() {

    }

    onClearClick() {

    }
}
